import React, { Component } from 'react';
import {graphql} from 'gatsby'
import Container from '../styles/container'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import styled from 'styled-components';
// import { convertReactPropstoHtmlAttributes } from 'react-helmet/lib/HelmetUtils'
// import { propTypes } from '@sanity/block-content-to-react'

const ImageFlexbox = styled.div`
  margin-bottom: ${props => props.theme.space[12]};
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: ${props => props.theme.space[3]};
  justify-items: stretch;
  position: relative;
  width: 100%;

  @media only screen and (min-width: ${props => props.theme.breakpoints[1]}) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    margin-top: ${props => props.theme.space[8]};
  }

  @media only screen and (min-width: ${props => props.theme.breakpoints[2]}) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
  }

  .text1, .text2 {
    p {
      font-size: ${props => props.theme.fontSizes[2]};
      line-height: ${props => props.theme.space[6]};

      @media only screen and (min-width: ${props => props.theme.breakpoints[2]}) {
        font-size: ${props => props.theme.fontSizes[2]};
        line-height: ${props => props.theme.space[6]};
      }
    }
  }

  .text1 {
    grid-column: 1 / span 1;
    grid-row: 2 / 3;
    padding: ${props => props.theme.space[3]};
    
    @media only screen and (min-width: ${props => props.theme.breakpoints[2]}) {
      grid-column: 1 / span 1;
      grid-row: 2 / 3;
    }
  }

  .text2 {
    grid-column: 1 / span 1;
    grid-row: 4 / 5;
    padding: ${props => props.theme.space[3]};

    @media only screen and (min-width: ${props => props.theme.breakpoints[2]}) {
      grid-column: 2 / span 1;
      grid-row: 4 / 5;
    }
  }
`;

const MusterImage = styled.div`
  position: relative;
  top: 0;
  left: 0;

  img {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    padding-bottom: ${props => props.theme.space[3]};
    opacity:1;
    transition: opacity 0.3s linear;
  }

  .image1 {
    position: relative;
    top: 0;
    left: 0;
  }

  .image2 {
    position: absolute;
    top: 0;
    left: 0;
  }

  .hidden{
    opacity:0;   
  }
`;


export const query = graphql`
  fragment SanityMusterImage on SanityMusterImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
      path
      fixed {
        src
      }
      fluid(maxHeight: 320, maxWidth: 320) {
        src
      }
    }
  }

  query MusterPageQuery {
    portfolio: sanityPortfolio(_id: { regex: "/(drafts.|)portfolio/" }) {
      textone
      texttwo
    },
    muster: allSanityMuster(
      sort: { fields: [publishedAt], order: DESC }
      filter: { publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          musterImage {
            ...SanityMusterImage
          }
          musterImageBack {
            ...SanityMusterImage
          }
          title
        }
      }
    }
  }
`


class Portfolio extends Component {
  constructor(props) {
    super(props);

    this.state = { 
      index: undefined
    };
  }

  render() {
    const muster = this.props.data.muster.edges;

    return (
      <Layout>
      <SEO
        title="Portfolio"
      />
      <Container>
        <ImageFlexbox>
          <div className="text1"><p>{this.props.data.portfolio.textone}</p></div>
          <div className="text2"><p>{this.props.data.portfolio.texttwo}</p></div>

          {muster.map((amuster, index) => {
            const url  = amuster.node.musterImage.asset.fluid.src;
            const url2 = amuster.node.musterImageBack.asset.fluid.src;
            
            return <MusterImage key={amuster.node.id}>
              <img className="image1" src={url} />
              <img className={this.state.index === index ? "image2" : "hidden image2"} 
                   src={url2} onMouseEnter={e => this.setState({ index: index })} 
                   onClick={e => this.setState({ index: index })}
                   onMouseLeave={e => this.setState({index: undefined})}/> </MusterImage>
          })}
        </ImageFlexbox>

      </Container>
    </Layout>
    )
  }
}


export default Portfolio
